<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-chip slot="activator" v-on="on">
          {{ title }}: {{ selectedItemsStr }}
        </v-chip>
      </template>

      <v-card width="600">
        <v-toolbar flat dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-alert
          class="ma-3"
          v-if="items.list.length === 0"
          border="left"
          colored-border
          color="red"
          elevation="2"
          icon="mdi-alert"
        >
          {{ emptyMessage }}
        </v-alert>
        <v-card-text v-else class="pa-0 d-flex">
          <v-row dense no-gutters>
            <v-col cols="12" lg="6">
              <v-sheet
                style="border:1px solid #ddd;max-height:400px; overflow-y:auto"
              >
                <v-text-field
                  v-model="search"
                  class="px-3"
                  dense
                  height="35"
                  full-width
                  append-outer-icon="mdi-magnify"
                  placeholder="검색"
                  hide-details
                  clearable
                ></v-text-field>
                <v-treeview
                  v-model="selected"
                  :items="tree"
                  :item-text="textString"
                  :item-key="keyString"
                  :search="search"
                  :selection-type="selectionType"
                  selectable
                  return-object
                  rounded
                  hoverable
                  activatable
                  open-all
                  @update:active="v => (selected = v)"
                  multiple-active
                  :active="selected"
                  :open="open"
                  @input="checkMax"
                >
                  <template v-slot:prepend="{ item }">
                    <v-avatar v-if="item.icon && item.icon.url" tile left>
                      <v-img
                        style="border:1px solid #ddd"
                        :src="item.icon.url"
                        class="rounded-lg "
                      ></v-img>
                    </v-avatar>
                  </template>
                </v-treeview>
              </v-sheet>
            </v-col>
            <v-col cols="12" lg="6">
              <v-sheet
                style="border:1px solid #ddd;max-height:400px; overflow-y:auto"
              >
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title
                      >선택한 항목
                      {{
                        selected.length > 0 ? `${selected.length}개` : '없음'
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="selected.length > 0">
                    <v-btn color="primary" text @click="removeAll" small
                      >모두 지우기</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item-group style="border-top:1px solid #ddd">
                  <v-list-item
                    dense
                    v-for="(list, index) in selected"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-avatar v-if="list.icon && list.icon.url" tile left>
                          <v-img
                            style="border:1px solid #ddd"
                            :src="list.icon.url"
                            class="rounded-lg "
                          ></v-img>
                        </v-avatar>
                        {{ list[textString] }}</v-list-item-title
                      >
                      <v-list-item-subtitle v-if="list.subtitle">{{
                        list.subtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="ma-0">
                      <v-btn icon>
                        <v-icon color="grey lighten-1" @click="remove(index)"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="submit()" :disabled="!isSubmit">
            적용
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    // menu 의 타이틀을 지정합니다.
    title: {
      type: String,
      required: true
    },
    // menu 의 전체아이템과 선택아이템을 지정합니다.
    items: {
      type: Object,
      required: true
    },
    // 아이템의 text 를 어떻게 보여줄지 정합니다.
    detail: {
      type: Boolean,
      default: false
    },
    // 화면이 처음 로드시 메뉴를 보여줄지 말지 정합니다.
    default: {
      type: Boolean,
      default: false
    },
    // 선택 아이템의 최소 개수를 지정합니다.
    min: {
      type: Number,
      default: 0
    },
    // 선택 아이템의 최대 개수를 지정합니다.
    max: {
      type: Number,
      default: Infinity
    },
    // treeview 에 사용될 key
    keyString: {
      type: String,
      required: true
    },
    // treeview 에 사용될 text
    textString: {
      type: String,
      required: true
    },
    // api 호출 여부를 지정합니다.
    api: {
      type: Boolean,
      default: false
    },
    // 아이템이 없을 경우 노출되는 메시지 입니다.
    emptyMessage: {
      type: String,
      default: ''
    },
    // db에 저장된 필터 사용 유무를 확인합니다.
    isSavedFilter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      advertisers: [],
      advertiserIds: [],
      open: ['all'],
      minDay: '2021-01-01',
      dates: [],
      selected: [],
      treeItem: [],
      originItem: [],
      selectionType: 'leaf',
      menu: false,
      search: null
    };
  },
  watch: {
    menu(value) {
      const selected = JSON.parse(JSON.stringify(this.items.selected));
      //현재 선택 아이템 갯수에 따라 autocomplete 선택 항목에서 삭제합니다.
      if (value === false && selected.length === 0) {
        this.$emit('remove');
      } else {
        this.selected = selected;
      }
    }
  },
  created() {
    // 선택된 항목이 존재하면 메뉴를 열지 않습니다.
    // console.log(this.title, this.items.selected.length);
    if (this.items.selected.length > 0) {
      this.menu = false;
      if (this.isSavedFilter === false) {
        this.$emit('callApi');
      }
    } else {
      this.menu = true;
    }

    this.originItem = JSON.parse(JSON.stringify(this.items.list));
    this.treeItem = this.originItem;
  },
  computed: {
    tree() {
      const obj = {};
      obj[this.textString] = '전체선택';
      obj[this.keyString] = 'all';
      obj['children'] = [...this.treeItem];

      return this.treeItem.length > 0 ? [obj] : [];
    },
    selectedItemsStr() {
      if (this.items.selected.length === 0) {
        return '';
      }
      const str = this.detail
        ? this.items.selected.map(v => v[this.textString]).join(',')
        : `${this.items.selected[0][this.textString]} ${
            this.items.selected.length > 1
              ? `외 ${this.items.selected.length - 1} 개`
              : ''
          }`;
      return str;
    },
    isSubmit() {
      return this.selected.length > 0 ? true : false;
    }
  },
  mounted() {
    this.selected = JSON.parse(JSON.stringify(this.items.selected));
  },
  methods: {
    submit() {
      if (this.selected.length < this.min) {
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: `최소 ${this.min} 개 이상 선택이 필요한 항목입니다.`,
          confirmButtonText: '확인'
        });
      } else if (this.selected.length > this.max) {
        this.$Swal.fire({
          icon: 'error',
          iconHtml: '!',
          html: `최대 ${this.max} 개 선택 가능한 항목입니다.`,
          confirmButtonText: '확인'
        });
      } else {
        this.items.selected = this.selected;
        this.menu = false;
        // api 가 true 인경우 callApi 호출후 callApi 에서 applyFitler를 emit 하도록 한다.
        if (this.api === true) {
          this.$emit('callApi');
        } else {
          this.$emit('applyFilter');
        }
      }
    },
    remove(index) {
      this.selected.splice(index, 1);
    },
    removeAll() {
      this.selected = [];
    },
    // max 값을 초과할 경우 최근선택한 아이템의 max 개 만큼만 선택하도록 합니다.
    checkMax(e) {
      if (e.length > this.max) {
        this.selected = e.slice(Math.max(e.length - this.max, 0));
      }
    }
  }
};
</script>
